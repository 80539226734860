@import 'src/utils/utils';

.modal-tarif {
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 20px;
    padding-right: 30px;

    @media(min-width: $md) {
      margin-bottom: 30px;
    }
  }

  &__title {
    @include Head-35-bold;
    margin: 0;
  }

  &__post-title {
    @include Text-16-reg;
    margin: 0;
    margin-top: 10px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  &__table {
    width: 100%;
  }

  &__row {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 5px;

    @media(min-width: $md) {
      display: table-row;
      margin-top: unset;
    }

    &:not(:first-child) {

      td {
        @media(min-width: $md) {
          padding-top: 5px;
        }
      }
    }

    em {
      font-style: normal;
      font-weight: 700;
      color: $Corporate-2;
    }
  }

  &__name {
    @include Text-16-bold;
    vertical-align: top;
    white-space: nowrap;

    @media(min-width: $md) {
      padding-right: 40px;
    }
  }

  &__value {
    @include Text-16-reg;
    vertical-align: top;
  }

  &__legal {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 18px;
    gap: 15px;
    flex-grow: 1;

    @media(min-width: $md) {
      margin-top: 30px;
      gap: 30px;
    }

    .button {
      margin-top: auto;
    }
  }
}